<template>
	<div class="mb-4">
		<div class="d-flex align-center">
			<v-icon class="mx-4"> mdi-magnify </v-icon>
			<v-text-field v-model="name" :placeholder="$t('search.searchInFolder')" />
		</div>
		<v-card rounded="xl">
			<v-list v-cloak rounded color="transparent" class="pa-0">
				<template v-for="folder in filteredSortedFolders">
					<ListItem :key="folder.id" :item="folder" :is-dir="true" />
				</template>
				<template v-for="file in filteredSortedFiles">
					<ListItem :key="file.id" :item="file" :is-dir="false" />
				</template>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'Listing',
	data() {
		return {
			name: '',
		}
	},
	components: {
		ListItem: () => import('@/components/explorer/ListItem.vue'),
	},
	computed: {
		filteredSortedFolders() {
			return this.sortedFolders.filter((item) => removeAccents(item.name).toLowerCase().includes(removeAccents(this.name).toLowerCase()))
		},
		filteredSortedFiles() {
			return this.sortedFiles.filter((item) => removeAccents(item.name).toLowerCase().includes(removeAccents(this.name).toLowerCase()))
		},
		sortedFolders() {
			return Array.prototype.slice.call(this.folders).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
		},
		sortedFiles() {
			return Array.prototype.slice.call(this.files).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
		},
		...mapGetters({
			folders: 'explorer/folders',
			files: 'explorer/files',
		}),
	},
}
</script>
